import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="not-found-page">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...<a href="/"></a></p>
      <p><a href="/">Return to homepage</a></p>
    </div>
  </Layout>
)

export default NotFoundPage
